<template>
  <RoundImage>
    <img class="logo" :src="url" :alt="alt" v-if="url">
    <i class="logo logo-star material-icons" v-else>star</i>
  </RoundImage>
</template>

<script>
import RoundImage from '@/components/RoundImage'

export default {
  name: 'ProductLogo',
  components: {
    RoundImage
  },
  props: {
    url: {
      type: String
    },
    alt: {
      type: String
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/swoop/variables';

.logo-star {
  font-size: 20px;
  color: var(--color-secondary-500);
}
</style>
