<template src="./deals.html"></template>
<style lang="scss" src="./deals.scss" scoped></style>

<script>
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { getApplicationListByStatus } from '@/api/opportunityApplication'
import ProductItem from '@/components/ProductItem'
import CurrencySymbol from '@/components/CurrencySymbol'

export default {
  name: 'FundingDeals',
  components: {
    ProductItem,
    CurrencySymbol
  },
  data() {
    return {
      dealsList: null
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    }),
    totalAmount() {
      if (!this.dealsList) return null
      return this.dealsList.reduce((acc, deal) => acc + deal.amount, 0)
    }
  },
  methods: {
    fillDealsList() {
      return getApplicationListByStatus({ companyId: this.companyId, status: 'ApplicationAccepted' })
        .then(res => {
          this.dealsList = res.data.sort((a, b) => b.maxSize - a.maxSize)
        })
        .catch(() => {
          this.dealsList = []
        })
    }
  },
  created() {
    this.fillDealsList()
  }
}
</script>
