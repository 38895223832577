<template>
  <div class="logo-wrapper">
    <div class="logo-holder" :style="{ backgroundColor: backgroundColor }">
      <slot class="logo">
        <img :src="url" :alt="alt" />
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoundImage',
  props: {
    url: {
      type: String
    },
    alt: {
      type: String
    },
    backgroundColor: {
      type: String
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/swoop/variables';

$size: 36px;
.logo-wrapper {
  flex-shrink: 0;
  flex-grow: 0;
}
.logo-holder {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  max-width: $size; // IE11
  width: $size;
  height: $size;
  border: 1px solid var(--color-primary-100);
  border-radius: 50%;
  background-color: $color-white;
  margin: 0;
  overflow: hidden;
  img,
  .logo {
    display: block;
    max-width: 100%;
    max-height: $size; // IE11
    margin: 0 auto;
  }
}
</style>
