<template>
  <div class="ui raised fluid card">
    <div class="content">
      <div class="header">
        <label class="name">{{ product.productName || product.providerName }}</label>
        <label class="meta amount">
          <template v-if="product.maxSize && $route.name !== 'funding-deals'"><currency-symbol />{{ product.minSize | commarize }}-{{ product.maxSize | commarize }}</template>
          <template v-else-if="product.amount || product.amount === 0"><currency-symbol />{{ product.amount | commarize }}</template>
          <template v-else>N/A</template>
        </label>
      </div>

      <ProductLogo
        :class="{
          'live-offer-logo': product.type === 'LiveOffer'
        }"
        :url="product.logoUri"
        :alt="product.productName"
      />
    </div>
    <div class="description">
      <label class="label-item">{{ type }}</label>
      <label class="label-item" v-if="product.type === 'Equity' && subCategory">{{ subCategory }}</label>
      <label class="label-item" v-if="'aprMin' in product && product.aprMin !== null">{{ product.aprMin }}%</label>
      <label class="label-item" v-else-if="product.type === 'Loan'">0%</label>
      <label class="label-item" v-if="'speed' in product && product.speed !== null && product.type !== 'Grant' && product.type !== 'Equity'">{{ speed }}</label>
    </div>
    <div class="action" v-if="!isApplication || product.status.toLowerCase() !== 'applicationaccepted'">
      <router-link
        :class="{
          'has-preferred-color': hasPreferredColor
        }"
        :style="{
          background: hasPreferredColor ? product.preferredColor : null
        }"
        :to="toDetailsRoute"
        v-slot="{ href, route }"
      >
        <a
          :href="href"
          @click.prevent="productDetailsLinkClickHandler(route)"
          class="ui button btn-primary medium circular fullwidth business-gradient"
          v-if="$route.name !== 'funding-deals'"
          >View {{ product.type === 'LiveOffer' ? 'offer' : product.type.toLowerCase() }} details</a
        >
      </router-link>
    </div>
  </div>
</template>

<script>
import ProductLogo from '@/components/ProductLogo'
import CurrencySymbol from '@/components/CurrencySymbol'
import { productTypeOptionsDic, productSubCategoryTypeOptionsDic, amplitudeEvents } from '@/utils/constants'

export default {
  name: 'ProductItem',
  components: {
    CurrencySymbol,
    ProductLogo
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    type() {
      if (this.product.type === 'LiveOffer') return 'Loan'
      const option = productTypeOptionsDic[this.product.type]
      return option.label || this.product.type
    },
    subCategory() {
      if (!this.product.subcategory || this.product.subcategory === 'Undefined') return null
      const option = productSubCategoryTypeOptionsDic[this.product.subcategory]
      return option.label || this.product.subcategory
    },
    isApplication() {
      return Object.prototype.hasOwnProperty.call(this.product, 'opportunityApplicationId')
    },
    toDetailsRoute() {
      return this.isApplication
        ? { name: 'deal-details', params: { opportunityApplicationId: this.product.opportunityApplicationId } }
        : { name: 'funding-explore-deal', params: { opportunityId: this.product.opportunityId } }
    },
    hasPreferredColor() {
      return this.product.type === 'LiveOffer' && 'preferredColor' in this.product
    },
    speed() {
      const { speed } = this.product
      switch (speed) {
        case 1:
          return '< 1 week'
        case 2:
          return '1 month'
        case 3:
          return '3 months'
        case 4:
          return '6 months'
        case 5:
          return '6 months+'
      }
      return 'N/A'
    }
  },
  methods: {
    productDetailsLinkClickHandler(route) {
      this.$emit('productDetailsClick')
      this.sendToFundingCheckoutAmplitude()
      this.$router.push(route)
    },
    sendToFundingCheckoutAmplitude() {
      /** Amplitude Analytics */
      this.$ma.addItem({
        [`Counter ${this.product.type} Details Viewed`]: 1
      })
      this.$ma.trackEvent({
        eventType: amplitudeEvents.fundingCheckout.VIEW_MATCH_DETAILS,
        eventProperties: {
          'Product ID': this.product.opportunityId,
          'Product Type': this.product.type,
          'Product Name': this.product.productName,
          'Product Vendor': this.product.providerName
        }
      })
      this.$ma.trackView({ viewName: 'MATCH_DETAILS' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/swoop/variables';

.card {
  height: 100%;
}
.card {
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  flex-grow: 1;
}
.product-params {
  flex-grow: 1;
}
.product-name {
  font-size: 14px;
  line-height: 16px;
}
.product-amount {
  font-size: 24px;
  line-height: 36px;
}
.product-logo {
  flex-shrink: 0;
}
.live-offer-logo {
  /deep/ {
    .logo-holder {
      max-width: 108px;
      width: 108px;
      height: 40px;
      padding: 2px;
      border-radius: 4px;
    }
  }
}

.labels {
  display: flex;
  // justify-content: space-between;
  .label-item {
    flex-grow: 1;
    flex-basis: 100%;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding: 4px;
    border: 1px solid $color-secondary;
    + .label-item {
      border-left: 0;
    }
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      background: $color-secondary;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.btn {
  width: 100%;
  &.has-preferred-color {
    opacity: 0.95;
    &:hover,
    &:active {
      opacity: 1;
    }
  }
}
.card {
  padding: 1rem 1.25rem;
  @media only screen and (min-width: $breakpoint-sm-max) {
    width: 300px;
  }
  height: 172px;
  .content {
    display: flex;

    .header {
      display: flex;
      flex-direction: column;
      .name {
        font-size: 14px;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 156px;
        margin-bottom: 4px;
      }
      .amount {
        font-size: 1.5rem;
        color: var(--color-primary-500);
        font-weight: 400;
      }
    }
    .live-offer-logo {
      /deep/ {
        .logo-holder {
          max-width: 108px;
          width: 108px;
          height: 40px;
          padding: 2px;
          border-radius: 4px;
          @media only screen and (max-width: 500px) {
            width: 75px;
          }
        }
      }
    }
  }
  .description {
    height: 24px;
    margin: 1rem 0rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .label-item {
      flex-grow: 1;
      flex-basis: 100%;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      padding: 4px;
      border: 1px solid $color-secondary;
      + .label-item {
        border-left: 0;
      }
      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background: $color-secondary;
      }
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
  .action {
    display: flex;
    justify-content: center;
  }
}
</style>
