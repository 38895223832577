var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui raised fluid card"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"header"},[_c('label',{staticClass:"name"},[_vm._v(_vm._s(_vm.product.productName || _vm.product.providerName))]),_c('label',{staticClass:"meta amount"},[(_vm.product.maxSize && _vm.$route.name !== 'funding-deals')?[_c('currency-symbol'),_vm._v(_vm._s(_vm._f("commarize")(_vm.product.minSize))+"-"+_vm._s(_vm._f("commarize")(_vm.product.maxSize)))]:(_vm.product.amount || _vm.product.amount === 0)?[_c('currency-symbol'),_vm._v(_vm._s(_vm._f("commarize")(_vm.product.amount)))]:[_vm._v("N/A")]],2)]),_c('ProductLogo',{class:{
        'live-offer-logo': _vm.product.type === 'LiveOffer'
      },attrs:{"url":_vm.product.logoUri,"alt":_vm.product.productName}})],1),_c('div',{staticClass:"description"},[_c('label',{staticClass:"label-item"},[_vm._v(_vm._s(_vm.type))]),(_vm.product.type === 'Equity' && _vm.subCategory)?_c('label',{staticClass:"label-item"},[_vm._v(_vm._s(_vm.subCategory))]):_vm._e(),('aprMin' in _vm.product && _vm.product.aprMin !== null)?_c('label',{staticClass:"label-item"},[_vm._v(_vm._s(_vm.product.aprMin)+"%")]):(_vm.product.type === 'Loan')?_c('label',{staticClass:"label-item"},[_vm._v("0%")]):_vm._e(),('speed' in _vm.product && _vm.product.speed !== null && _vm.product.type !== 'Grant' && _vm.product.type !== 'Equity')?_c('label',{staticClass:"label-item"},[_vm._v(_vm._s(_vm.speed))]):_vm._e()]),(!_vm.isApplication || _vm.product.status.toLowerCase() !== 'applicationaccepted')?_c('div',{staticClass:"action"},[_c('router-link',{class:{
        'has-preferred-color': _vm.hasPreferredColor
      },style:({
        background: _vm.hasPreferredColor ? _vm.product.preferredColor : null
      }),attrs:{"to":_vm.toDetailsRoute},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var route = ref.route;
return [(_vm.$route.name !== 'funding-deals')?_c('a',{staticClass:"ui button btn-primary medium circular fullwidth business-gradient",attrs:{"href":href},on:{"click":function($event){$event.preventDefault();return _vm.productDetailsLinkClickHandler(route)}}},[_vm._v("View "+_vm._s(_vm.product.type === 'LiveOffer' ? 'offer' : _vm.product.type.toLowerCase())+" details")]):_vm._e()]}}],null,false,2078578687)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }